import { createApp } from 'vue'
import VueCookies from 'vue-cookies'
import moment from 'moment';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@/assets/css/tailwind.css'

const app = createApp(App)

app.config.globalProperties.$moment=moment;

app.use(store)
app.use(router)
app.use(VueCookies, { expires: '7d'})
app.mount('#app')

// createApp(App).use(VueMatchHeights, {disabled: [768],}).use(store).use(router).mount('#app')
