import Vue from "vue";
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: HomeView
  },
  {
    path: '/add-website',
    name: 'Add Website',
    component: () => import(/* webpackChunkName: "add-website" */ '../views/AddWebsiteView.vue')
  },
  {
    path: '/chatbot-demo',
    name: 'Chatbot Demo',
    component: () => import(/* webpackChunkName: "chatbot-demo" */ '../views/ChatbotDemoView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
